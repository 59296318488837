<template>
  <div class="background-img-4 flx flx-d-column">
    <div class="title">
      <h2 class="step">5º PASSO</h2>
      <h2 class>ESCOLHA O LAYOUT</h2>
    </div>
    <div class="video-block-container fx-center fx-wrap">
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '1.mp4' ? 'selected': ''"
        @click="chooseTemplate('1.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/1.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '2.mp4' ? 'selected': ''"
        @click="chooseTemplate('2.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/2.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '3.mp4' ? 'selected': ''"
        @click="chooseTemplate('3.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/3.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '4.mp4' ? 'selected': ''"
        @click="chooseTemplate('4.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/4.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '5.mp4' ? 'selected': ''"
        @click="chooseTemplate('5.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/5.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '6.mp4' ? 'selected': ''"
        @click="chooseTemplate('6.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/6.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '7.mp4' ? 'selected': ''"
        @click="chooseTemplate('7.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/7.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="video-block fx-center"
        :class="templateIdentifier == '8.mp4' ? 'selected': ''"
        @click="chooseTemplate('8.mp4')"
      >
        <video controls>
          <source src="../../assets/videos/8.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="fx-center">
      <button class="btn-yellow" type="button" @click="sendTemplate">Próximo Passo</button>
    </div>
    <div class="mancha-step">
      <img src="../../assets/img/element-2.png" alt="imagem" />
    </div>
  </div>
</template>

<style scoped>
.video-block video {
  width: 70%;
  margin-bottom: 2em;
  border: 2px solid #feea2f;
}
.video-block {
  border: 3px solid transparent;
}
.video-block.selected {
  border-color: red;
}
</style>

<script>
import router from "../../router";
import store from "../../store";

export default {
  name: "ChooseNuggetTemplate",
  data() {
    return {
      templateIdentifier: "1.mp4",
    };
  },
  created() {
    if (store.state.audioNugget && store.state.audioNugget.templateIdentifier) {
      // Step is already set, skip it
      router.push({
        name: "ExportNugget",
      });
      return;
    }
  },
  methods: {
    chooseTemplate(tid) {
      this.templateIdentifier = tid;
    },
    async sendTemplate() {
      store.commit("mergeAudioNugget", {
        templateIdentifier: this.templateIdentifier,
      });
      router.push({
        name: "ExportNugget",
      });
    },
  },
};
</script>
